import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import grapesjs from 'grapesjs';
import 'grapesjs/dist/css/grapes.min.css';
import grapesjsPresetWebpage from 'grapesjs-preset-webpage';
import grapesjsBlocksBasic from 'grapesjs-blocks-basic';
import grapesjsPluginForms from 'grapesjs-plugin-forms';
import grapesjsComponentCountdown from 'grapesjs-component-countdown';
import grapesjsPluginExport from 'grapesjs-plugin-export';
import grapesjsTabs from 'grapesjs-tabs';
import grapesjsCustomCode from 'grapesjs-custom-code';
import grapesjsTouch from 'grapesjs-touch';
import grapesjsParserPostcss from 'grapesjs-parser-postcss';
import grapesjsTooltip from 'grapesjs-tooltip';
import grapesjsTuiImageEditor from 'grapesjs-tui-image-editor';
import grapesjsTyped from 'grapesjs-typed';
import grapesjsStyleBg from 'grapesjs-style-bg';

const GrapesEditor = forwardRef((props, ref) => {
  const editorRef = useRef(null);
  const editorInstance = useRef(null);

  useEffect(() => {
    if (editorRef.current) {
      editorInstance.current = grapesjs.init({
        container: editorRef.current,
        showOffsets: true,
        storageManager: {
          autoload: false,
          autosave: false
        },
        selectorManager: { componentFirst: true },
        plugins: [
          grapesjsPresetWebpage,
          grapesjsBlocksBasic,
          grapesjsPluginForms,
          grapesjsComponentCountdown,
          grapesjsPluginExport,
          grapesjsTabs,
          grapesjsCustomCode,
          grapesjsTouch,
          grapesjsParserPostcss,
          grapesjsTooltip,
          grapesjsTuiImageEditor,
          grapesjsTyped,
          grapesjsStyleBg
        ],
        pluginsOpts: {
          grapesjsPresetWebpage: {},
          grapesjsBlocksBasic: { stylePrefix: "", flexGrid: 1 },
          grapesjsTuiImageEditor: {
            script: [
              'https://uicdn.toast.com/tui.code-snippet/v1.5.2/tui-code-snippet.min.js',
              'https://uicdn.toast.com/tui-color-picker/v2.2.7/tui-color-picker.min.js',
              'https://uicdn.toast.com/tui-image-editor/v3.15.2/tui-image-editor.min.js'
            ],
            style: [
              'https://uicdn.toast.com/tui-color-picker/v2.2.7/tui-color-picker.min.css',
              'https://uicdn.toast.com/tui-image-editor/v3.15.2/tui-image-editor.min.css',
            ],
          },
          grapesjsTabs: {
            tabsBlock: { category: 'Extra' }
          },
          grapesjsTyped: {
            block: {
              category: 'Extra',
              content: {
                type: 'typed',
                'type-speed': 40,
                strings: [
                  'Text row one',
                  'Text row two',
                  'Text row three',
                ],
              }
            }
          },
        },
      });
    }
  }, []);

  useImperativeHandle(ref, () => ({
    getContent: () => {
      const html = editorInstance.current.getHtml();
      const css = `<style>${editorInstance.current.getCss()}</style>`;
      return { html, css };
    },
    setContent: (content) => {
      const interval = setInterval(() => {
        if (editorInstance.current) {
          editorInstance.current.setComponents(content.html);
          editorInstance.current.setStyle(content.css.replace(/<style>|<\/style>/g, ''));
          clearInterval(interval); // Stop the interval once the content is set
        }
      }, 100);
    }
  }));


  return (
    <div style={{ height: '100vh' }} ref={editorRef}></div>
  );
});

export default GrapesEditor;
